import { FavoritesResult } from 'favorites';
import { browserApi } from 'lib/httpClient';
import { MatrixParams } from 'matrix';

async function favoriteClient(params: Partial<MatrixParams>): Promise<FavoritesResult> {
  const { data } = await browserApi.get<FavoritesResult>('/favorites', {
    params,
  });
  return data;
}

export default favoriteClient;
