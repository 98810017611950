import { browserApi } from 'lib/httpClient';
import { SearchEventResult } from 'searchEvent.d';

async function searchEventClient(query: string): Promise<SearchEventResult> {
  const { data } = await browserApi.get<SearchEventResult>(`/search/event${query}`);

  return data;
}

export default searchEventClient;
