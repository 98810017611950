import { makeAutoObservable } from 'mobx';

import { ExternalPageResult } from 'externalPage';
import { CardPreviousView } from 'matrix';
import externalPageClient from 'services/externalPage/externalPageClient';

export type ExternalPageHydration = {
  externalPageResult: ExternalPageResult | null;
  cardPreviousView: CardPreviousView | null;
};

export class ExternalPageStore {
  isFetching: boolean;

  externalPageResult: ExternalPageResult | null = null;

  cardPreviousView: CardPreviousView | null;

  isHydrated: boolean;

  hasReportError: boolean;

  constructor() {
    this.isFetching = false;
    this.externalPageResult = null;
    this.cardPreviousView = null;
    this.isHydrated = false;
    this.hasReportError = false;

    makeAutoObservable(this);
  }

  hydrate = (data: ExternalPageHydration) => {
    if (this.isHydrated) return;

    this.externalPageResult = data.externalPageResult;

    this.cardPreviousView = data.cardPreviousView;

    this.isHydrated = true;
  };

  setFetching = (state: boolean) => {
    this.isFetching = state;
  };

  setResults = (results: ExternalPageResult, join = false) => {
    if (!join) this.externalPageResult = results;
    else if (this.externalPageResult) {
      this.externalPageResult.components = [...(this.externalPageResult?.components || []), ...results.components];
    }
  };

  setHasReportError = (value: boolean) => { this.hasReportError = value; };

  getInitialExternalPage = async (params: { [key: string]: string }) => {
    this.setFetching(true);
    externalPageClient({
      ...params,
      include_response: true,
    }).then((response) => {
      this.setResults(response);
    })
      .finally(() => {
        this.setFetching(false);
      });
  };
}
