import { browserApi } from 'lib/httpClient';
import { SearchPlacesResult } from 'searchPlaces.d';

async function searchPlacesClient(query: string): Promise<SearchPlacesResult> {
  const { data } = await browserApi.get<SearchPlacesResult>(`/search/places${query}`);

  return data;
}

export default searchPlacesClient;
