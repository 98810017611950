import { browserApi } from 'lib/httpClient';
import { SearchEvent } from 'searchEvent.d';

async function searchClient(title?: string): Promise<SearchEvent[]> {
  const { data } = await browserApi.get<SearchEvent[]>('/search', {
    params: { title },
  });
  return data;
}

export default searchClient;
