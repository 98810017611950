import { browserApi } from 'lib/httpClient';
import { SearchEventResult } from 'searchEvent';

async function venueGetEventsClient(query: string): Promise<SearchEventResult> {
  const { data } = await browserApi.get<SearchEventResult>(`/local/events?${query}`);

  return data;
}

export default venueGetEventsClient;
