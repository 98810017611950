import { browserApi } from 'lib/httpClient';
import { MatrixParams, MatrixResult } from 'matrix';

async function getHomeDataClient(params?: Partial<MatrixParams>): Promise<MatrixResult> {
  const { data } = await browserApi.get<MatrixResult>('/home', {
    params,
  });

  return data;
}

export default getHomeDataClient;
