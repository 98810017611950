'use client';

import { enableStaticRendering } from 'mobx-react-lite';
import React, { createContext, ReactNode } from 'react';
import { RootStore, RootStoreHydration } from 'stores/RootStore';

enableStaticRendering(typeof window === 'undefined');

let store: RootStore;
export const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = 'StoreContext';

export function initializeStore(initialData?: RootStoreHydration): RootStore {
  const _store = store ?? new RootStore();

  if (initialData && !store) {
    _store.hydrate(initialData);
  }
  if (typeof window === 'undefined') return _store;
  if (!store) store = _store;

  return _store;
}

export function RootStoreProvider({
  children,
  hydrationData,
}: {
  children: ReactNode;
  hydrationData: RootStoreHydration;
}) {
  const initializedStore = initializeStore(hydrationData);

  return (
    <StoreContext.Provider value={initializedStore}>{children}</StoreContext.Provider>
  );
}
