import { makeAutoObservable } from 'mobx';
import searchClient from 'services/search/searchClient';
import { SearchEvent as SearchEventType } from 'searchEvent.d';

export class SearchStore {
  isFetching: boolean;

  searchEventResult: SearchEventType[];

  constructor() {
    this.isFetching = false;
    this.searchEventResult = [];

    makeAutoObservable(this);
  }

  setFetching = (state: boolean) => {
    this.isFetching = state;
  };

  setResults = (results: SearchEventType[]) => {
    this.searchEventResult = results;
  };

  executeSearch = async (query: string) => {
    try {
      this.setFetching(true);
      const searchEventResult = await searchClient(query);
      this.setResults(searchEventResult);
      this.setFetching(false);
    } catch (error) {
      this.setFetching(false);
    }
  };
}
