import { ExternalPageResult } from 'externalPage';
import { browserApi } from 'lib/httpClient';
import { MatrixParams } from 'matrix';

async function externalPageClient(params: Partial<MatrixParams>): Promise<ExternalPageResult> {
  const { data } = await browserApi.get<ExternalPageResult>('/external-page', {
    params,
  });
  return data;
}

export default externalPageClient;
