import { action, makeAutoObservable } from 'mobx';
import { SearchPlacesResult } from 'searchPlaces.d';
import { SearchEventResult } from 'searchEvent.d';
import { SearchProducersResult } from 'searchProducers.d';
import searchEventClient from 'services/searchEvent/searchEventClient';
import searchPlacesClient from 'services/searchPlaces/searchPlacesClient';
import searchProducersClient from 'services/searchProducers/searchProducersClient';
import { initialSearchDataResult, urlParamsSearch } from 'constants/search';
import { ParamsMapSearch } from 'ParamsResult';
import { convertObjectToQueryParam } from 'utils/misc';
import locationStore from 'stores/LocationStore';
import { ANYWHERE_LOCATION_JSON } from 'constants/index';
import { convertQueryParamToObject } from 'utils/QueryParams/convertQueryParam';
import { PageType } from 'constants/enums/search';

export type SearchContentBaseHydration = SearchPlacesResult | SearchEventResult | SearchProducersResult;
export class SearchContentBaseStore<SearchResult extends SearchPlacesResult | SearchEventResult | SearchProducersResult> {
  isFetching: boolean;

  searchDataResult: SearchResult;

  paramsMapSearch?: ParamsMapSearch;

  otherSearchResult: SearchResult;

  isHydrated: boolean;

  isHydratedOtherResult: boolean;

  isHydratedParams: boolean;

  filterAnymoreLocation: boolean;

  initialData: SearchResult = initialSearchDataResult as unknown as SearchResult;

  typePage: PageType;

  searchDataClient: (query: string) => Promise<SearchResult>;

  constructor(searchDataClient: (query: string) => Promise<SearchResult>) {
    this.searchDataClient = searchDataClient;
    this.isHydrated = false;
    this.isHydratedParams = false;
    this.isHydratedOtherResult = false;
    this.isFetching = false;
    this.searchDataResult = this.initialData;
    this.paramsMapSearch = undefined;
    this.filterAnymoreLocation = false;
    this.otherSearchResult = this.initialData;
    this.typePage = PageType.EVENTS;

    makeAutoObservable(this);
  }

  get totalPages() {
    if (!this.searchDataResult.limit) return 1;
    return Math.ceil(this.searchDataResult.total / this.searchDataResult.limit);
  }

  get totalPagesOtherResult() {
    if (!this.otherSearchResult.limit) return 0;
    return Math.ceil(this.otherSearchResult.total / this.otherSearchResult.limit);
  }

  setIsHydrated = action((state: boolean) => {
    this.isHydrated = state;
  });

  setIsHydratedOtherResult = action((state: boolean) => {
    this.isHydratedOtherResult = state;
  });

  setIsHydratedParams = action((state: boolean) => {
    this.isHydratedParams = state;
  });

  setTypePage = (type: PageType) => {
    this.typePage = type;
  };

  setFetching = (state: boolean) => {
    this.isFetching = state;
  };

  setResults = (results: SearchResult) => {
    this.searchDataResult = results;
  };

  setOtherResults = (results: SearchResult) => {
    this.otherSearchResult = results;
  };

  setParamsMapSearch = (params: ParamsMapSearch) => {
    this.paramsMapSearch = params;
  };

  fetchPaginationData = async ({
    query,
    isMobile,
    page,
    onSuccess,
  }: {
    query: string;
    isMobile: boolean;
    page: number | string;
    onSuccess: () => void;
  }) => {
    try {
      this.setFetching(true);
      const newQuery = convertQueryParamToObject(query);
      newQuery.page = String(page) || '1';

      const result = await this.searchDataClient(convertObjectToQueryParam(newQuery));

      const mobilePayload = {
        ...result,
        data: [...this.searchDataResult.data, ...result.data],
      };

      if (this.otherSearchResult?.data?.length && !this.searchDataResult.data.length) {
        this.setOtherResults(isMobile ? mobilePayload : { ...result });
      } else this.setResults(isMobile ? mobilePayload : { ...result });

      this.setFetching(false);

      if (!isMobile) onSuccess();
    } catch (error) {
      this.setResults(this.initialData);
      this.setFetching(false);
    }
  };

  fetchData = async (query: string) => {
    try {
      this.setFetching(true);
      const filterOtherCity = this.filterAnymoreLocation
      && locationStore?.selectedLocation?.city !== ANYWHERE_LOCATION_JSON?.city;
      const queryParams = convertObjectToQueryParam({ ...this.paramsMapSearch, l: filterOtherCity ? urlParamsSearch.ignoreLocation : '' });
      const newQuery = queryParams ? `${queryParams}&${query.slice(1)}` : query;
      const result = await this.searchDataClient(newQuery);

      if (filterOtherCity) {
        this.setOtherResults({ ...result });
      } else this.setResults({ ...result });

      if (locationStore?.selectedLocation?.city === ANYWHERE_LOCATION_JSON?.city && this.otherSearchResult?.data?.length) {
        this.setOtherResults(this.initialData);
      }

      this.setFetching(false);
    } catch (error) {
      this.setResults(this.initialData);
      this.setFetching(false);
    }
  };

  hydrate = (data: SearchResult) => {
    if (data && !this.isHydrated) {
      this.setResults(data);
    }
    this.setIsHydrated(true);
  };

  hydrateParams = (params: ParamsMapSearch) => {
    if (params && !this.isHydratedParams) {
      const { page, ...rest } = params;
      this.setParamsMapSearch(rest);
    }
    this.setIsHydratedParams(true);
  };

  hydrateOtherResult = (data: SearchResult) => {
    if (data && !this.isHydratedOtherResult) {
      this.setOtherResults(data);

      if (!this.searchDataResult?.data?.length) this.filterAnymoreLocation = true;
    }
    this.setIsHydratedOtherResult(true);
  };
}

export const searchEventStore = new SearchContentBaseStore(searchEventClient);
export const searchPlacesStore = new SearchContentBaseStore(searchPlacesClient);
export const searchProducersStore = new SearchContentBaseStore(searchProducersClient);
