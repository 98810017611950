export function convertObjectToQueryParam(params: object) {
  return Object.keys(params).reduce((acc, cur, idx) => {
    const value = params[cur as keyof typeof params];
    if (value) {
      return `${acc}${idx === 0 ? '?' : '&'}${cur}=${value}`;
    }

    return acc;
  }, '');
}

export function convertPriceParamToNewFormat(value: string) {
  if (!value) return '';

  const isFree = !Number(value);

  return isFree ? '1' : '0';
}

export function getFilterDate(dateStr: string) {
  if (!dateStr) return { start_date: '', end_date: '' };

  const [start_date, end_date] = dateStr.includes(',') ? dateStr.split(',') : dateStr.split('%2C');
  return { start_date, end_date };
}

export function convertQueryParamToObject(query: string) {
  const urlParams = new URLSearchParams(query);
  const paramsObject: Record<string, string | number> = {};
  urlParams.forEach((value, key) => {
    paramsObject[key] = value;
  });
  return paramsObject;
}
