import { makeAutoObservable } from 'mobx';

export type CollectionHydration = {
    screen: string,
};

export class CollectionStore {
  screen: string;

  isHydrated: boolean;

  constructor() {
    this.screen = '';
    this.isHydrated = false;
    makeAutoObservable(this);
  }

  hydrate = (data: CollectionHydration) => {
    if (this.isHydrated) return;

    this.screen = data.screen;
    this.isHydrated = true;
  };
}
