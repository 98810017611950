import { makeAutoObservable } from 'mobx';

import favoriteClient from 'services/favoritos/favoriteClient';
import { SeoDataType } from 'seoData';
import { FavoritesResult } from 'favorites.d';
import { CardPreviousView } from 'matrix';
import { getCardPreviousView } from 'utils/getCardPreviousView';

export type FavoritesHydration = {
  favoritesResult: FavoritesResult | null;
  cardPreviousView: CardPreviousView | null;
  seoData: Partial<SeoDataType>;
};

export class FavoritesStore {
  isFetching: boolean;

  favoritesResult: FavoritesResult | null = null;

  cardPreviousView: CardPreviousView | null;

  isHydrated: boolean;

  hasReportError: boolean;

  currentPage: number;

  totalComponentsLoaded: number;

  constructor() {
    this.isFetching = false;
    this.favoritesResult = null;
    this.cardPreviousView = getCardPreviousView();
    // this.seoData = null;
    this.isHydrated = false;
    this.hasReportError = false;
    this.currentPage = 1;
    this.totalComponentsLoaded = 0;

    makeAutoObservable(this);
  }

  hydrate = (data: FavoritesHydration) => {
    if (this.isHydrated) return;

    // this.seoData = data.seoData;

    this.favoritesResult = data.favoritesResult;

    this.cardPreviousView = data.cardPreviousView;

    this.totalComponentsLoaded = data?.favoritesResult?.components.length || 0;

    this.isHydrated = true;
  };

  setFetching = (state: boolean) => {
    this.isFetching = state;
  };

  setResults = (results: FavoritesResult, join = false) => {
    if (!join) {
      this.favoritesResult = results;
      this.totalComponentsLoaded = results.components.length;
    } else if (this.favoritesResult) {
      this.favoritesResult.components = [...(this.favoritesResult?.components || []), ...results.components];
      this.totalComponentsLoaded += results.components.length;
    }
  };

  // setSeoData = (seoData: SeoDataType) => {
  //   this.seoData = seoData;
  // };

  setHasReportError = (value: boolean) => { this.hasReportError = value; };

  getInitialFavorites = async (userId?: number) => {
    this.setFetching(true);

    favoriteClient({
      include_response: true,
      components_limit: 4,
      user_id: userId,
      events_ids: this.cardPreviousView?.recents?.join(',') || '',
    }).then((response) => {
      this.setResults(response);
      this.currentPage = 2;
    })
      .finally(() => {
        this.setFetching(false);
      });
  };

  getAllFavorites = async (userId?: number) => {
    if (this.totalComponentsLoaded >= (this.favoritesResult?.components_total || 0)) return;

    this.setFetching(true);
    favoriteClient({
      include_response: true,
      afterComponentsCount: this.totalComponentsLoaded,
      components_limit: 4,
      components_page: this.currentPage,
      user_id: userId,
      events_ids: this.cardPreviousView?.recents?.join(',') || '',
    }).then((response) => {
      this.setResults(response, true);
      this.currentPage += 1;
    })
      .finally(() => {
        this.setFetching(false);
      });
  };
}
