import { SearchEventResult } from 'searchEvent.d';
import { SearchPlacesResult } from 'searchPlaces.d';
import { SearchProducersResult } from 'searchProducers.d';
import searchEventClient from 'services/searchEvent/searchEventClient';
import searchPlacesClient from 'services/searchPlaces/searchPlacesClient';
import searchProducersClient from 'services/searchProducers/searchProducersClient';
import searchEventClosedClient from 'services/searchEventClosed/searchEventClosedClient';
import { LocationHydration, LocationStore } from './LocationStore';
import { SearchStore } from './SearchStore';
import { VenueStore, VenueHydration } from './VenueStore';
import { AuthHydration, AuthStore } from './AuthStore';
import { CollectionStore, CollectionHydration } from './CollectionStore';
import { HomeHydration, HomeStore } from './HomeStore';
import { SearchContentBaseStore } from './SearchContentBaseStore';
import { FavoritesHydration, FavoritesStore } from './FavoriteStore';
import { ExternalPageHydration, ExternalPageStore } from './ExternalPageStore';

export type RootStoreHydration = {
  locationHydration?: LocationHydration;
  collectionHydration?: CollectionHydration;
  searchEventHydration?: SearchEventResult;
  searchPlacesHydration?: SearchPlacesResult;
  searchProducersHydration?: SearchProducersResult;
  homeHydration?: HomeHydration;
  venueHydration?: VenueHydration;
  favoriteHydration?: FavoritesHydration;
  closedSearchEventHydration?: SearchEventResult;
  externalPageHydration?: ExternalPageHydration;
  authHydration?: AuthHydration;
};

export class RootStore {
  locationStore: LocationStore;

  favoritesStore: FavoritesStore;

  externalPageStore: ExternalPageStore;

  venueStore: VenueStore;

  searchStore: SearchStore;

  closedSearchEventStore: SearchContentBaseStore<SearchEventResult>;

  searchEventStore: SearchContentBaseStore<SearchEventResult>;

  searchPlacesStore: SearchContentBaseStore<SearchPlacesResult>;

  searchProducersStore: SearchContentBaseStore<SearchProducersResult>;

  authStore: AuthStore;

  collectionStore: CollectionStore;

  homeStore: HomeStore;

  constructor() {
    this.locationStore = new LocationStore();
    this.searchStore = new SearchStore();
    this.venueStore = new VenueStore();
    this.favoritesStore = new FavoritesStore();
    this.externalPageStore = new ExternalPageStore();
    this.authStore = new AuthStore();
    this.collectionStore = new CollectionStore();
    this.homeStore = new HomeStore();
    this.searchEventStore = new SearchContentBaseStore(searchEventClient);
    this.searchPlacesStore = new SearchContentBaseStore(searchPlacesClient);
    this.searchProducersStore = new SearchContentBaseStore(searchProducersClient);
    this.closedSearchEventStore = new SearchContentBaseStore(searchEventClosedClient);
  }

  hydrate(data: RootStoreHydration) {
    if (data.locationHydration?.selectedLocation && data.locationHydration?.cities) {
      this.locationStore.hydrate(data.locationHydration);
    }

    if (data.collectionHydration?.screen) {
      this.collectionStore.hydrate(data.collectionHydration);
    }

    if (data.searchEventHydration) {
      this.searchEventStore.hydrate(data.searchEventHydration as SearchEventResult);
    }

    if (data.closedSearchEventHydration) {
      this.closedSearchEventStore.hydrate(data.closedSearchEventHydration as SearchEventResult);
    }

    if (data.venueHydration?.venueResult) {
      this.venueStore.hydrate(data.venueHydration);
    }

    if (data.searchPlacesHydration) {
      this.searchPlacesStore.hydrate(data.searchPlacesHydration as SearchPlacesResult);
    }

    if (data.searchProducersHydration) {
      this.searchProducersStore.hydrate(data.searchProducersHydration as SearchProducersResult);
    }

    if (data.homeHydration?.screen) {
      this.homeStore.hydrate(data.homeHydration);
    }

    if (data.favoriteHydration?.favoritesResult) {
      this.favoritesStore.hydrate(data.favoriteHydration);
    }

    if (data.externalPageHydration?.externalPageResult) {
      this.externalPageStore.hydrate(data.externalPageHydration);
    }

    if (data.authHydration) {
      this.authStore.hydrate(data.authHydration);
    }
  }
}
