import { makeAutoObservable } from 'mobx';
import { SearchEventResult } from 'searchEvent';
import { SeoDataType } from 'seoData';
import SendVenueReport from 'services/venue/SendVenueReport';
import venueGetEventsClient from 'services/venue/venueGetEventsClient';
import { checkEmptyFields } from 'utils/checkEmptyFields';

import { VenueResult } from 'venue.d';

export type VenueHydration = {
  venueResult: VenueResult | null;
};

export class VenueStore {
  isFetching: boolean;

  venueResult: VenueResult | null = null;

  events: SearchEventResult = {
    data: [],
    limit: 0,
    page: 0,
    total: 0,
  } as unknown as SearchEventResult;

  isHydrated: boolean;

  seoData: SeoDataType;

  modalReportIsOpen: boolean;

  isReportSent: boolean;

  hasReportError: boolean;

  constructor() {
    this.isFetching = true;
    this.venueResult = null;
    this.seoData = null;
    this.isHydrated = false;
    this.isReportSent = false;
    this.hasReportError = false;
    this.modalReportIsOpen = false;

    makeAutoObservable(this);
  }

  hydrate = (data: VenueHydration) => {
    if (this.isHydrated) return;

    this.venueResult = data.venueResult;

    const eventGallery = data.venueResult?.components?.find((component) => component.template === 'events-gallery');

    if (eventGallery && 'events' in eventGallery.data) {
      this.events = {
        data: eventGallery.data.events,
        limit: eventGallery.data.limit,
        page: eventGallery.data.page,
        total: eventGallery.data.total,
      };
    }

    this.isHydrated = true;
  };

  setFetching = (state: boolean) => {
    this.isFetching = state;
  };

  setResults = (results: VenueResult) => {
    this.venueResult = results;
  };

  setEventsResults = (events: SearchEventResult) => {
    this.events = {
      ...events,
      data: [...this.events.data, ...events.data],
    };
  };

  setSeoData = (seoData: SeoDataType) => {
    this.seoData = seoData;
  };

  setIsReportSent = (value: boolean) => { this.isReportSent = value; };

  setModalReportIsOpen = (value: boolean) => { this.modalReportIsOpen = value; };

  setHasReportError = (value: boolean) => { this.hasReportError = value; };

  getEvents = async (query: string) => {
    try {
      this.setFetching(true);
      const searchEventResult = await venueGetEventsClient(query);
      this.setEventsResults(searchEventResult);
      this.setFetching(false);
    } catch (error) {
      this.setFetching(false);
    }
  };

  sendReport = async (localSlug: string, localName: string, form: FormData) => {
    const { browserLogger } = await import('utils/logger/browserLogger');
    const { info: browserInfo, error: browserError } = browserLogger();
    const funcSignature = 'ModalStore.sendReport';

    try {
      this.setFetching(true);
      this.setHasReportError(false);

      const senderName = form.get('senderName') as string;
      const senderEmail = form.get('senderEmail') as string;
      const message = form.get('message') as string;
      const allowContact = form.get('allowContact');

      const reqBody = {
        localSlug,
        localName,
        reportType: 'local',
        eventUrl: window.location.href,
        owner: 'White-lion',
        message,
        senderName,
        senderEmail,
        allowContact: Boolean(allowContact) || false,
      };

      checkEmptyFields({ message });
      await SendVenueReport(reqBody);

      browserInfo({
        message: `[ReportEvent][${localSlug} - ${localName}] Success`,
        data: 'No Extra data',
        extra: {
          input: reqBody,
          output: {},
        },
      }, funcSignature);

      window?.dataLayer?.push({
        stckEventCategory: 'ReportContent',
        stckEventLabel: reqBody?.allowContact ? '.send_report.success.allow_contact.' : '.send_report.success.',
        stckEventAction: 'click',
        stckCustomDimension: {
          local_id: reqBody.localSlug,
        },
        event: 'stck',
      });

      this.setIsReportSent(true);
    } catch (error) {
      browserError({
        message: `[ReportEvent][${localSlug} - ${localName}] Error`,
        data: 'No Extra data',
        extra: {
          input: {
            localSlug,
            localName,
            eventUrl: window.location.href,
            owner: 'White-lion',
          },
          output: {},
        },
      }, funcSignature);

      window?.dataLayer?.push({
        stckEventCategory: 'ReportContent',
        stckEventLabel: '.send_report.failure.',
        stckEventAction: 'click',
        stckCustomDimension: {
          local_id: localSlug,
        },
        event: 'stck',
      });

      this.setHasReportError(true);
    } finally {
      this.setFetching(false);
    }
  };

  resetModal = () => {
    this.setHasReportError(false);
    this.setIsReportSent(false);
  };
}

const venueStore = new VenueStore();
export default venueStore;
