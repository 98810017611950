import { browserApi } from 'lib/httpClient';

type sendReportParams = {
  allowContact: boolean
  localSlug: string | number
  eventUrl: string
  localName: string
  owner: string
  message: string
  senderEmail: string
  senderName: string
  reportType: string
}

export type ReportResponse = {
  ReportEvent: {
    status: string
  } & sendReportParams
}

async function SendVenueReport({
  localSlug,
  localName,
  eventUrl,
  owner,
  message,
  senderEmail,
  senderName,
  allowContact,
  reportType,
}: sendReportParams): Promise<{data: ReportResponse}> {
  const url = `${process.env.NEXT_PUBLIC_EVENT_PAGE_URL}/api/event-bff/sendMessage/report`;
  const { data } = await browserApi.post<{data: ReportResponse}>(url, {
    localSlug,
    localName,
    eventUrl,
    owner,
    message,
    senderEmail,
    senderName,
    allowContact,
    reportType,
  });
  return data;
}

export default SendVenueReport;
