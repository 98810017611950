import { browserApi } from 'lib/httpClient';
import { SearchEventResult } from 'searchEvent.d';

async function searchEventClosedClient(query: string): Promise<SearchEventResult> {
  try {
    const { data } = await browserApi.get<SearchEventResult>(`search-closed/event${query}`);
    return data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Erro ao buscar eventos fechados:', error);
    throw error;
  }
}

export default searchEventClosedClient;
