import { MatrixParams, MatrixResult } from 'matrix';
import { makeAutoObservable } from 'mobx';

import getHomeDataClient from 'services/home/fetchHomeClient';

export type HomeHydration = {
  screen: string;
  homeResult: MatrixResult;
};

export class HomeStore {
  screen: string;

  isHydrated: boolean;

  homeResult: MatrixResult | null = null;

  constructor() {
    this.screen = '';
    this.isHydrated = false;
    makeAutoObservable(this);
  }

  setScreen = (screen: string) => {
    this.screen = screen;
  };

  setHomeResult = (homeResult: MatrixResult) => {
    this.homeResult = homeResult;
  };

  hydrate = (data: HomeHydration) => {
    if (this.isHydrated) return;

    this.screen = data.screen;
    this.homeResult = data.homeResult;
    this.isHydrated = true;
  };

  getScreenData = async (params?: Partial<MatrixParams>) => {
    const response = await getHomeDataClient(params);

    return response;
  };
}

const homeStore = new HomeStore();
export default homeStore;
