import { browserApi } from 'lib/httpClient';
import { SearchProducersResult } from 'searchProducers.d';

async function searchProducersClient(query: string): Promise<SearchProducersResult> {
  const { data } = await browserApi.get<SearchProducersResult>(`/search/producers${query}`);

  return data;
}

export default searchProducersClient;
